import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductListService } from '../../services/product-list.service';
import { PlpInfo } from '../../models/plpInfo';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrl: './product-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductFilterComponent implements OnInit {

  filterList: Observable<PlpInfo>;
  public searchText: string = "";
  turbineToggle: boolean;
  categoryToggle: boolean;
  public userLoggedIn: Observable<any>;

  constructor(
    private productsService: ProductListService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.loginBanner$;
    this.getProductsList();
  }

  public getProductsList() {
    this.filterList = this.productsService.productsList$;
  }

  public updateTurbines(event: any) {
    this.searchText = event.target.value;
  }

  getCategoryIndex(facet) {
    return facet?.at(0)?.name === 'allCategories' ? 0 : facet?.at(1)?.name === 'allCategories' ? 1 : -1;
  }

  valueChange(query, name, isSelected) {
    let searchQuery = this.productsService.searchQuery + this.productsService.filterQuery;
    if (!isSelected) {
      query = searchQuery + query + `"${name}"`;
    } else if (searchQuery?.includes(query + `"${name}"`)) {
      query = searchQuery?.replace(query + `"${name}"`, '');
    }
    this.productsService.paginationReset.next(true);
    this.productsService.getProductsList({ query: query, currentPage: 0, pageSize: 30, fields: "FULL" });
  }

  filterToggle(categoryName: string) {
    if (categoryName === 'turbine') {
      this.turbineToggle = !this.turbineToggle;
    } else if (categoryName === 'category') {
      this.categoryToggle = !this.categoryToggle;
    }
  }

  ngOnDestroy() {
    this.globalService.clearMessagesOnDestroy();
  }
}
